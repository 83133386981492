import 'babel-polyfill'
import $ from 'jquery'
import nav from './util/nav'
import footer from './util/footer'
import { validate } from 'jquery-validation'
import { lazySizes } from 'lazysizes'
import active from './util/active'
import accordion from './util/accordion'
import tab from './util/tab'
import datePicker from './util/datePicker'
import smoothScroll from './util/smoothScroll'
import customize from './util/customize'
import campaign from './util/campaign'
import campaignTab from './util/campaignTab'
import { headerShow,toTop,footerBanner } from './util/scrollEvent'
import { formValid,formReset,enterDisallow,errorJump,submitCheck } from './util/formValid'
import moment from 'moment'
import { slide } from './util/slide'
import form from './util/form'

require('moment/locale/ja');

// lazySizes
lazySizesConfig=lazySizesConfig||{};
lazySizesConfig.lazyClass='lazy';
lazySizesConfig.srcAttr='data-src';
lazySizesConfig.loadMode=2;

$(() => {
    active();
    accordion();
    campaign();
    campaignTab();
    tab();
    smoothScroll();
    customize();
    formValid();
    formReset();
    enterDisallow();
    errorJump();
    submitCheck();
    headerShow();
    toTop();
    footer();
    nav();
    form();
});

var selectColor=function () {
    // 現在選択されてる項目によって色設定
    var select=$('[data-target-select]');

    if (select.find('option:selected').hasClass('disabled-color')) {
        $('select').css({ 'color': '#959595' });
    }

    // 項目が変更された時、条件によって色変更
    select.on('change',function () {
        if ($(this).find('option:selected').hasClass('disabled-color')) {
            $(this).css({ 'color': '#959595' });
        } else {
            $(this).css({ 'color': '#434343' });
        }
    });
};

$(function () {
    selectColor();
});

const hopeTimes=$('#hopeTime').html()

window.onload=function () {
    if (moment().hours()>=18) {
        $('#date').datepicker('option','minDate','+1d')
    }
    var code=sessionStorage.getItem('code')
    $('#code').text(code)

    writeInput()
    writeInputCallentry()
}

$('#date').change(function () {
    var dateFormat='YYYY/MM/DD'
    var hourFormat='HH:mm'
    var now=moment()
    var input=moment($(this).val())

    $('#hopeTime').html(hopeTimes)

    if (input.isSame(now.format(dateFormat),'days')) {
        if (6<=now.hours()&&now.hours()<18) {
            var hour=0
            var time=''
            if (30<now.minute()) {
                hour=now.hours()+4
                time=hour+':00'
            } else {
                hour=now.hours()+3
                time=hour+':30'
            }

            $('#hopeTime option').each(function () {
                if ($(this).val().indexOf(time)>=0) {
                    $(this).remove()
                    return false
                } else if ($(this).val()==='選択してください') {
                    //Through
                } else {
                    $(this).remove()
                }
            })
        }
    }
})

$('#inputs').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('form',JSON.stringify(tmpArray));
    return true;
});

$('#callentry').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('callentry',JSON.stringify(tmpArray));
    return true;
});
$('#estimateFrom').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('estimate',JSON.stringify(tmpArray));
    estimateSubmit();
    return false;
  });

  function contactData (sendData, key){
    const url='https://prod.cd.012grp.tech/api/v2/entry'
    // const url='https://dev.cd.012grp.tech/api/v2/entry'
    // const unique_id = Math.random().toString(32).substring(2)

    $.ajax({
      url: url,
      async: false,
      type: "POST",
      dataType: "json",
      data: sendData,
      beforeSend: function(request){
        request.setRequestHeader("x-api-key", key);
      },
    })
    .done((data) => {
      console.log('成功')
    })
    .fail((data) => {
      console.log('失敗')
      throw new Error('contactData接続失敗')
    })
  }

//   count参照
var countId;
function getCount (countPath) {
  return $.ajax({
      url: countPath,
      dataType:'text',
      async: false
  })
}

  var flag=false
  $('#submitOK').click(function () {
    if (flag) return
    var inputs=sessionStorage.getItem('form')
    sessionStorage.clear()
    flag=true
    var countPath = '../../phpCount/count.txt'
    getCount(countPath).done(function(result) {
        countId = Number(result)+1
        return result;
        }).fail(function(result) {
    })
    // ここからコンタクトデータの処理---開始--------------------------------
    // 仕様書URL:https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0

    // お申し込み
    // const unique_id = Math.random().toString(32).substring(2)
    const sendData = {
      'phone': $('#inputTel').text(),
      'subject': "お申し込み【Softbank光/アフィリ】",
      'click_id': countId,
      'name': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}`,
      'name_kana': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
      'mail': $('#inputEmail').text(),
      'zip': $('#inputZip').text(),
      'address': $('#inputPref').text(),
      'address_2': `${$('#inputAddr01').text()} ${$('#inputAddr02').text()}`,
      'question': $('#inputRemark').text(),
      'others': JSON.stringify({
      '[お名前]': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}`,
      '[フリガナ]': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
      '[メールアドレス]': $('#inputEmail').text(),
      '[電話番号]': $('#inputTel').text(),
      '[住所]': `${$('#inputZip').text()} ${$('#inputPref').text()} ${$('#inputAddr01').text()} ${$('#inputAddr02').text()}`,
      '[設置情報]': $('#inputHousingform').text(),
      '[お引っ越し予定日]': $('#inputMovingday').text(),
      '[ご要望・ご質問など]': $('#inputRemark').text(),
    })

    };
    try {
      contactData(sendData, 'Kj5p3QQMxMJkl5RkShQllInHt')
    // dev
    //   contactData(sendData, '4CtQFpFN1xHnkTUa5Hw4orYSF')

    } catch (e) {
      window.alert(`接続エラーが発生しました。${e}`)
      return
    }


    //--------------------------------------------------------------------
    // 以上でコンタクトデータの処理は終了です。
    //--------------------------------------------------------------------

    $.ajax({
      url: '../../mail.php',
      type: 'POST',
      dataTypes: 'json',
        data: JSON.parse(inputs)

    }).done(function (res) {
        if (res) {
            sessionStorage.clear()
            sessionStorage.setItem('code',res)
            location.href='../thanks'
        }
    })
})
var flag=false
$('#submitCallentry').click(function () {
    if (flag) return
    var callentry=sessionStorage.getItem('callentry')
    sessionStorage.clear()
    flag=true
    var countPath = '../../callentryForm/phpCount/count.txt'
    getCount(countPath).done(function(result) {
        countId = Number(result)+1
        return result;
        }).fail(function(result) {
    })

    // ここからコンタクトデータの処理---開始--------------------------------
    // 仕様書URL:https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0

    // 電話予約
    // const unique_id = Math.random().toString(32).substring(2)
    const sendData = {
      'phone': $('#inputTel').text(),
      'subject': "電話折り返し予約【Softbank光/アフィリ】",
      'click_id': countId,
      'name': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}`,
      'name_kana': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
      'question': $('#inputRemark').text(),
      'others': JSON.stringify({
          '[電話番号]': $('#inputTel').text(),
          '[お名前]': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}`,
          '[フリガナ]': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
          '[繋がりやすい時間帯]': $('#inputTimezone').text(),
          '[ご要望・ご質問など]': $('#inputRemark').text()
        })
    };

    try {
      contactData(sendData, 'G1DjnbWlVFLwpC1ToEMaPLqoh')
    //   dev
    //   contactData(sendData, 'vvCU29IL9S85Ni6M2bkh9EK8v')

    } catch (e) {
      window.alert(`接続エラーが発生しました。${e}`)
      return
    }


//--------------------------------------------------------------------
// 以上でコンタクトデータの処理は終了です。
//--------------------------------------------------------------------


    $.ajax({
        url: '../../callentryForm/mail.php',
        type: 'POST',
        dataTypes: 'json',
        data: JSON.parse(callentry)

    }).done(function (res) {
        if (res) {
            sessionStorage.clear()
            sessionStorage.setItem('code',res)
            location.href='../thanks'
        }
    })
})
function estimateSubmit() {
    if (!$('#submitEstimate').hasClass('is-disabled')) {
        var estimate=sessionStorage.getItem('estimate')
        sessionStorage.clear()
        // areaCheckFlag=true
        $.ajax({
            url: '../../estimateForm/mail.php',
            type: 'POST',
            dataTypes: 'json',
            data: JSON.parse(estimate)

        }).done(function (res) {
            if (res) {
                sessionStorage.clear()
                sessionStorage.setItem('code',res)
                location.href='../thanks'
            }
        })
    }
    return false;
}
$('#back').click(function () {
    history.back()
})

function writeInput() {
    var inputs=JSON.parse(sessionStorage.getItem('form'||"null"))

    dispConfirm(inputs,'firstname','inputFirstName')
    dispConfirm(inputs,'lastname','inputLastName')
    dispConfirm(inputs,'firstkana','inputFistKana')
    dispConfirm(inputs,'lastkana','inputLastKana')
    dispConfirm(inputs,'tel','inputTel')
    dispConfirm(inputs,'mail','inputEmail')
    dispConfirm(inputs,'zip','inputZip')
    dispConfirm(inputs,'pref','inputPref')
    dispConfirm(inputs,'addr01','inputAddr01')
    dispConfirm(inputs,'addr02','inputAddr02')
    dispConfirm(inputs,'housingform','inputHousingform')
    dispConfirm(inputs,'movingday','inputMovingday')
    dispConfirm(inputs,'note','inputRemark')
}

function dispConfirm(inputs,name,id) {

    if ($('#inputEmail').length) {
        var tmp=inputs.find(function (input) {
            return input.name===name;
        });
        if (tmp.value!=="0") {
            $('#'+id).text(tmp.value)
        } else {
            $('#'+id).text('')
        }
    }
}

function writeInputCallentry() {
    var callentry=JSON.parse(sessionStorage.getItem('callentry'||"null"))

    dispConfirmCallentry(callentry,'firstname','inputFirstName')
    dispConfirmCallentry(callentry,'lastname','inputLastName')
    dispConfirmCallentry(callentry,'firstkana','inputFistKana')
    dispConfirmCallentry(callentry,'lastkana','inputLastKana')
    dispConfirmCallentry(callentry,'tel','inputTel')
    dispConfirmCallentry(callentry,'timezone','inputTimezone')
    dispConfirmCallentry(callentry,'note','inputRemark')
}

function dispConfirmCallentry(callentry,name,id) {
    if ($('#inputFirstName').length) {
        var tmp=callentry.find(function (input) {
            return input.name===name;
        });
        if (tmp.value!=="0") {
            $('#'+id).text(tmp.value)
        } else {
            $('#'+id).text('')
        }
    }
}

function dispConfirmOnArray(inputs,name,id) {
    var tmp=inputs.filter(function (v) {
        return v.name===name
    })

    var values=[]

    tmp.forEach(function (v) {
        values.push(v.value)
    })

    var str=values.join(',')

    $('#'+id).text(str)
}

$('input[name="usingService"]').change(function () {
    if ($('input[name="usingService"]:checked')) {
        $('input[name="usingService"]').parent('.c-form__group__radioItem').removeClass('is-active');
        $(this).parent('.c-form__group__radioItem').addClass('is-active');
    }
});

$('input[name="housingForm"]').change(function () {
    if ($('input[name="housingForm"]:checked')) {
        $('input[name="housingForm"]').parent('.c-form__group__radioItem').removeClass('is-active');
        $(this).parent('.c-form__group__radioItem').addClass('is-active');
    }
});

$('input[name="smartphone"]').change(function () {
    if ($('input[name="smartphone"]:checked')) {
        $('input[name="smartphone"]').parent('.c-form__group__radioItem').removeClass('is-active');
        $(this).parent('.c-form__group__radioItem').addClass('is-active');
    }
});
