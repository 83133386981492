import $ from 'jquery'

let trigger=trigger||{};
trigger={
    tab: $('[data-trigger-tab]')
};

let target=target||{};
target={
    tab: $('[data-target-tab]')
};

export default function () {
    if (!trigger.tab.length) return;
    trigger.tab.on('click',function () {
        if ($(this).hasClass('plan__tabItem')) {
            if (!$(this).hasClass('is-active')) {
                $('.plan__itemBox').children(target.tab).toggleClass('is-active');
                $('.plan__tab a').removeClass('is-active');
                $(this).toggleClass('is-active');
            }
        } else {
            if (!$(this).hasClass('is-active')) {
                $('.campaign__tab a').removeClass('is-active');
                $('.campaign__tabItem').toggleClass('is-active');
                $(this).toggleClass('is-active');
            }
        }
    });
}