import $ from 'jquery'

let trigger = trigger || {};
trigger = {
    levelTable: $('[data-trigger-levelTable]'),
    close: $('[data-trigger-close]'),
    materialList: $('[data-trigger-materialList]'),
    materialListClose: $('[data-trigger-materialList-close]')
};

let target = target || {};
target = {
    overlay: $('[data-target-overlay]'),
    image: $('[data-target-image]'),
    materialListImage: $('[data-target-materialList-image]')
};


export default function() {
    confirmTable();
    materialList();
}

// レベル指標
function confirmTable() {
    trigger.levelTable.on('click', function() {
        $([target.image[0], target.overlay[0]]).toggleClass('is-active');
        scroll()
    });

    trigger.close.on('click', function() {
        $([target.image[0], target.overlay[0]]).toggleClass('is-active');
    });
}

// 教材一覧
function materialList() {
    trigger.materialList.on('click', function() {
        $([target.materialListImage[0], target.overlay[0]]).toggleClass('is-active');
        scroll()
    });

    trigger.materialListClose.on('click', function() {
        $([target.materialListImage[0], target.overlay[0]]).toggleClass('is-active');
    });
}


