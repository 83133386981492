import $ from 'jquery'

let trigger=trigger||{};
trigger={
	footer: $('[data-trigger-footer]')
};

let target=target||{};
target={
	body: $('[data-target-body]'),
	header: $('[data-target-header]'),
	headerShow: $('[data-target-headerShow]'),
	toTop: $('[data-target-totop]'),
	footer: $('[data-target-footer]'),
	footerShow: $('[data-target-footerShow]'),
	footerBanner: $('[data-target-footerBanner]'),
	cta: $('[data-target-cta]'),
	window: $(window)
};

export function toTop() {
	if (!target.toTop.length) return;
	$(document).on('scroll',() => {
		let scrollDistance=$(document).scrollTop();
		if (scrollDistance>200) {
			target.toTop.addClass('is-show');
		} else {
			target.toTop.removeClass('is-show');
		}
	});
}

export function headerShow() {
	if (!target.header.length) return;
	// $(document).on('scroll',() => {
	// 	let headerHeight=target.header.height();
	// 	// let aboutPosition=target.headerShow.offset().top;
	// 	let showHeight=headerHeight;
	// 	let scrollDistance=$(document).scrollTop();
	// 	if (scrollDistance>showHeight) {
	// 		target.header.addClass('is-hide');
	// 		console.log('test');
	// 	} else {
	// 		target.header.removeClass('is-hide');
	// 	}
	// });
	// $(window).on("scroll touchmove",function () { //スクロール中に判断する
	// 	let headerHeight=target.header.height();
	// 	let scrollDistance=$(document).scrollTop();
	// 	if (scrollDistance>200) {
	// 		target.header.stop(); //アニメーションしている場合、アニメーションを強制停止
	// 		target.header.css('display','none').delay(100).fadeIn('400');
	// 	} else {
	// 		// target.header.css('display','block')
	// 		target.header.removeClass('is-hide');
	// 	}
	// 	//スクロール中は非表示にして、500ミリ秒遅らせて再び表示
	// });
}

// export function footerBanner() {
// 	if (!target.header.length) return;
// 	$(document).on('scroll', () => {
// 		let windowHeight = target.window.height();
// 		let footerBannerHeight = target.footerBanner.height();
// 		let aboutPosition = target.headerShow.offset().top;
// 		let footerPosition = $('.c-form__footer').offset().top;
// 		let showPosition = aboutPosition - windowHeight;
// 		let hidePosition = footerPosition - footerBannerHeight - windowHeight;
// 		let scrollDistance = $(document).scrollTop();
// 		if (scrollDistance > showPosition) {
// 			target.footerBanner.addClass('is-show');
// 			if (scrollDistance > hidePosition) {
// 				target.footerBanner.removeClass('is-show');
// 			}
// 		} else {
// 			target.footerBanner.removeClass('is-show');
// 		}
// 	});
// }